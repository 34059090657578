<script>
  import VueSlickCarousel from "vue-slick-carousel";
  import MonthEmployeeLargeCard from "./MonthEmployeeLargeCard.vue";

  export default {
    components: {
      Modal: () => import("@/components/modals/ModalPost.vue"),
      MonthEmployeeLargeCard,
      VueSlickCarousel,
    },
    props: ["employees", "openVisorByReaction"],
    computed: {
      employeesOrDefault() {
        const currentDate = new Date();
        const defaultData = {
          year: currentDate.getFullYear(),
          month: currentDate.getMonth(),
        };

        if (!this.employees?.length) {
          return [defaultData];
        }

        return this.employees;
      },
      settingsCarrousel() {
        return {
          dots: false,
          infinite: false,
          initialSlide: this.getInitialSlide(this.employees),
          arrows: true,
          responsive: [
            {
              breakpoint: 700,
              settings: {
                arrows: false,
              },
            },
          ],
        };
      },
    },
    methods: {
      openModal(payload) {
        this.$emit("openModal", payload);
      },
      getInitialSlide(employees = []) {
        const indexUnView = employees.findIndex((employee) => !employee.viewers.some(({ user }) => user._id === this.$user._id));

        if (indexUnView === -1) {
          return 0;
        }

        return indexUnView;
      },
    },
  };
</script>

<template>
  <div class="monthEmployeeCarrousel" v-if="employeesOrDefault.length">
    <VueSlickCarousel v-bind="settingsCarrousel" class="monthEmployeeCarrousel__carrousel">
      <template #prevArrow="">
        <div class="monthEmployeeCarrousel__leftArrow"><iconic name="chevronLeft" /></div>
      </template>
      <MonthEmployeeLargeCard @openModal="() => openModal(employee)" v-for="employee in employeesOrDefault" :key="employee._id" :employee="employee" :openVisorByReaction="openVisorByReaction" />
      <template #nextArrow="">
        <div class="monthEmployeeCarrousel__rigthArrow"><iconic name="chevronRight" /></div>
      </template>
    </VueSlickCarousel>
  </div>
</template>

<style lang="scss">
  .monthEmployeeCarrousel {
    font-family: Arial, sans-serif;
    height: 100%;
    width: 100%;
    &__carrousel {
      height: 100%;
    }
    &__rigthArrow .iconic,
    &__leftArrow .iconic {
      font-size: 40px;
      color: #272727;
    }
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div {
      height: 100%;
    }
    .slick-slide {
      padding: 0 5px;
      width: 350px;
    }
    .slick-prev {
      left: 20px;
    }
    .slick-next {
      right: 20px;
    }
    .slick-prev,
    .slick-next {
      top: 55%;
      z-index: 1;
      height: 50px;
      width: 50px;
      display: block;
      justify-content: center;
      align-items: center;
      font-weight: bolder;
      background: transparent;
      &:hover > .iconic {
        transition: 0.2s ease-in-out;
        color: $primary_color;
      }
      &::before {
        content: "";
      }
    }
    @media screen and (min-width: 1024px) {
      .slick-prev,
      .slick-next {
        top: 71.5%;
      }
    }
  }
</style>
