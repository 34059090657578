<script>
  export default {
    props: ["employee"],
  };
</script>

<template>
  <div class="AvatarMonthEmployee">
    <div class="AvatarMonthEmployee__stars">
      <img src="../../assets/img/stars.png" alt="star" class="AvatarMonthEmployee__star AvatarMonthEmployee__star--one" />
      <img src="../../assets/img/stars.png" alt="star" class="AvatarMonthEmployee__star AvatarMonthEmployee__star--two" />
      <img src="../../assets/img/stars.png" alt="star" class="AvatarMonthEmployee__star AvatarMonthEmployee__star--three" />
    </div>
    <div class="AvatarMonthEmployee__container">
      <div class="AvatarMonthEmployee__wrapper">
        <img draggable="false" class="AvatarMonthEmployee__image" :src="employee?.monthEmployeeImg" :alt="employee?.user?.name" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .AvatarMonthEmployee {
    @include Flex(column);
    width: 56px;
    height: 84px;
    position: relative;
    &__stars {
      @include Flex(row);
      height: 28px;
    }
    &__star {
      width: 18px;
      height: 18px;
      &--two {
        width: 20px;
        margin-bottom: 8px;
      }
    }
    &__container {
      width: 100%;
      height: 100%;
      border: 6px solid transparent;
      border-radius: 50%;
      background-image: linear-gradient(
        #91693a 3%,
        #a07a43 10%,
        #c8a65d 24%,
        #dfbf6c 31%,
        #b48a42 50%,
        #bb944c 56%,
        #d1b169 65%,
        #f3df96 77%,
        #f7e59c 79%,
        #e6d08c 81%,
        #c7aa6e 85%,
        #af8d57 89%,
        #9d7846 93%,
        #936b3c 96%,
        #906739 100%
      );
    }
    &__wrapper {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px solid white;
    }
    &__image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px solid $primary-color;
      object-fit: cover;
    }
    @media (min-width: 768px) {
      width: 70px;
      height: 98px;
    }
    @media (min-width: 1024) {
      width: 100px;
      height: 133px;
      &__stars {
        height: 33px;
      }
      &__star {
        width: 20px;
        height: 20px;
        &--two {
          width: 23px;
          margin-bottom: 8px;
        }
      }
    }
    @media (min-width: 1440px) {
      width: 100px;
      height: 128px;
    }
  }
</style>
